@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
.rendered {
   ul {
      @apply list-disc;
      @apply pl-4;
    }
    ol {
      @apply list-decimal;
      @apply pl-4;
    }
    h1 {
        @apply text-2xl;
    }
    h2 {
        @apply text-xl;
    }
    h3 {
        @apply text-lg;
    }
  }
}

